import React, { useState, useEffect } from 'react'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
// actions
import { addSysParamOverride, fetchAllSysparam } from '../../../redux/actions/setting'
// selectors
import { getAllSysparam } from '../../../redux/selectors/setting'
// components
import TransferList from './TransferList'
import PageContainer from '../../PageContainer'
import ListSysparam from './ListSysparam'
import { Button } from '@material-ui/core'


const AllSysparams = (props) => {

    const {
        addSysParamOverride,
        allSysparam,
        fetchAllSysparam
    } = props

    const [sysparamForOverride, setSysparamForOverride] = useState([])

    useEffect(() => {
        if (allSysparam.length === 0)
            fetchAllSysparam()
    })

    function handleAddSysparamOverride() {
        console.log(sysparamForOverride)
        addSysParamOverride(sysparamForOverride[0]) // test with one sysparam
    }

    function onItemClick(sysparm) {
        const tempList = fromJS(sysparamForOverride).toJS()
        tempList.push(sysparm)
        setSysparamForOverride(tempList)
    }

    return (
        <PageContainer>
            {/* <TransferList /> */}
            <Button color="secondary" onClick={handleAddSysparamOverride}>Add to sysparam override</Button>
            <ListSysparam onItemClick={onItemClick}/>
        </PageContainer>
    )
}

const mapStateToProps = (state) => ({
    allSysparam: getAllSysparam(state),
})

const mapDispatchToProps = {
    addSysParamOverride,
    fetchAllSysparam
}

export default connect(mapStateToProps, mapDispatchToProps)(AllSysparams)
